<template>
  <!-- Genenral Information Section -->
  <div class="d-flex flex-column flex-md-row" style="gap: 50px">
    <!-- General Information - Left Section -->
    <div class="flex-grow-1 mt-8" style="min-width: 45%">
      <h4 class="mt-2">Product information</h4>
      <div class="mt-8 font-weight-medium">
        <p class="d-flex justify-space-between my-4">
          <span>Name</span>
          <span class="text-capitalize">
            {{ product.name }}
          </span>
        </p>
        <hr style="border-top: 1px solid #d3d3d3" />
        <p class="d-flex justify-space-between my-4">
          <span>Type of product</span>
          <span class="text-capitalize">
            {{ product.type }}
          </span>
        </p>
        <hr style="border-top: 1px solid #d3d3d3" />
        <p class="d-flex justify-space-between my-4">
          <span>Code-barres</span>
          <span>{{ product.barcodes[0]?.code }}</span>
        </p>
        <hr style="border-top: 1px solid #d3d3d3" />
        <p class="d-flex justify-space-between my-4">
          <span>Shelf</span>
          <span>
            {{ shelf?.name }}
          </span>
        </p>
        <hr style="border-top: 1px solid #d3d3d3" />
        <p class="d-flex justify-space-between my-4">
          <span>Category</span>
          <span>{{ root?.name }}</span>
        </p>
        <hr style="border-top: 1px solid #d3d3d3" />
        <p class="d-flex justify-space-between my-4">
          <span>Sub Category</span>
          <span>{{ category?.name }}</span>
        </p>
        <hr style="border-top: 1px solid #d3d3d3" />
        <p class="d-flex justify-space-between my-4">
          <span>Brand</span>
          <span>{{ product.brand }}</span>
        </p>
        <hr style="border-top: 1px solid #d3d3d3" />
        <p class="d-flex justify-space-between my-4">
          <span>Primary supplier</span>
          <span>{{ mainProvider.name }}</span>
        </p>
        <hr style="border-top: 1px solid #d3d3d3" />
        <div class="d-flex justify-space-between my-4">
          <span>Secondary suppliers</span>
          <div>
            <span
              v-for="(secondaryProvider, index) in secondaryProviders"
              v-bind:key="index"
            >
              {{ secondaryProvider?.name }}<br />
            </span>
          </div>
        </div>
        <hr style="border-top: 1px solid #d3d3d3" />
      </div>
    </div>
    <!-- /General Information - Left Section -->
    <!-- General Information - Right Section -->
    <div class="flex-grow-1 mt-8" style="min-width: 45%">
      <h4 class="mt-2">Item information</h4>
      <div class="mt-8 font-weight-medium">
        <p class="d-flex justify-space-between my-4">
          <span>Item NET Weight</span>
          <span>
            {{ product.product_info?.item_net_weight }}
            {{ product.product_info?.item_net_weight_unit }}
          </span>
        </p>
        <hr style="border-top: 1px solid #d3d3d3" />
        <p class="d-flex justify-space-between my-4">
          <span>Item GROSS Weight</span>
          <span>
            {{ product.product_info?.item_gross_weight }}
            {{ product.product_info?.item_gross_weight_unit }}
          </span>
        </p>
        <hr style="border-top: 1px solid #d3d3d3" />
        <p class="d-flex justify-space-between my-4">
          <span>Item NET Volume</span>
          <span>
            {{ product.product_info?.item_net_volume }}
            {{ product.product_info?.item_net_volume_unit }}
          </span>
        </p>
        <hr style="border-top: 1px solid #d3d3d3" />
        <p class="d-flex justify-space-between my-4">
          <span>Item GROSS Volume</span>
          <span>
            {{ product.product_info?.item_gross_volume }}
            {{ product.product_info?.item_gross_volume_unit }}
          </span>
        </p>
        <hr style="border-top: 1px solid #d3d3d3" />
        <p class="d-flex justify-space-between my-4">
          <span>Units per package</span>
          <span>
            {{ product.product_info?.package_units }}
          </span>
        </p>
        <hr style="border-top: 1px solid #d3d3d3" />
        <p class="d-flex justify-space-between my-4">
          <span>Package width (cm)</span>
          <span>
            {{ product.product_info?.package_width }}
          </span>
        </p>
        <hr style="border-top: 1px solid #d3d3d3" />
        <p class="d-flex justify-space-between my-4">
          <span>Package Depth (cm)</span>
          <span>
            {{ product.product_info?.package_depth }}
          </span>
        </p>
        <hr style="border-top: 1px solid #d3d3d3" />
        <p class="d-flex justify-space-between my-4">
          <span>Package Height (cm)</span>
          <span>
            {{ product.product_info?.package_height }}
          </span>
        </p>
        <hr style="border-top: 1px solid #d3d3d3" />
        <p class="d-flex justify-space-between my-4">
          <span>Package NET weight</span>
          <span>
            {{ product.product_info?.package_net_weight }}
            {{ product.product_info?.package_net_weight_unit }}
          </span>
        </p>
        <hr style="border-top: 1px solid #d3d3d3" />
        <p class="d-flex justify-space-between my-4">
          <span>Package NET volume</span>
          <span>
            {{ product.product_info?.package_gross_weight }}
            {{ product.product_info?.package_gross_weight_unit }}
          </span>
        </p>
        <hr style="border-top: 1px solid #d3d3d3" />
        <p class="d-flex justify-space-between my-4">
          <span>Package per palette</span>
          <span>
            {{ product.product_info?.palette_units }}
          </span>
        </p>
        <hr style="border-top: 1px solid #d3d3d3" />
        <p class="d-flex justify-space-between my-4">
          <span>Palette Width (m)</span>
          <span>
            {{ product.product_info?.palette_width }}
          </span>
        </p>
        <hr style="border-top: 1px solid #d3d3d3" />
        <p class="d-flex justify-space-between my-4">
          <span>Palette Depth (m)</span>
          <span>
            {{ product.product_info?.palette_depth }}
          </span>
        </p>
        <hr style="border-top: 1px solid #d3d3d3" />
        <p class="d-flex justify-space-between my-4">
          <span>Palette Height (m)</span>
          <span>
            {{ product.product_info?.palette_height }}
          </span>
        </p>
        <hr style="border-top: 1px solid #d3d3d3" />
        <p class="d-flex justify-space-between my-4">
          <span>Palettte NET Weight</span>
          <span>
            {{ product.product_info?.package_net_weight }}
            {{ product.product_info?.package_net_weight_unit }}
          </span>
        </p>
        <hr style="border-top: 1px solid #d3d3d3" />
        <p class="d-flex justify-space-between my-4">
          <span>Palettte NET Volume</span>
          <span>
            {{ product.product_info?.palette_gross_weight }}
            {{ product.product_info?.palette_gross_weight_unit }}
          </span>
        </p>
        <hr style="border-top: 1px solid #d3d3d3" />
        <p class="d-flex justify-space-between my-4">
          <span>Point of supply</span>
          <span class="text-capitalize">
            {{ product.point_of_supply }}
          </span>
        </p>
        <hr style="border-top: 1px solid #d3d3d3" />
        <p class="d-flex justify-space-between my-4">
          <span>Imported product</span>
          <span class="text-capitalize">
            {{ !!product.imported }}
          </span>
        </p>
        <hr style="border-top: 1px solid #d3d3d3" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    "product",
    "shelf",
    "root",
    "category",
    "mainProvider",
    "secondaryProviders",
  ],
};
</script>