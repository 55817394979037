<template>
  <v-form
    ref="form"
    v-model="valid"
  >
    <v-container
      fluid
      class="px-6"
    >
      <div>
        <h1 class="display-1 font-weight-bold">Modifier le produit</h1>
        <p class="subtitle-1 grey--text">
          <strong>ID:</strong> {{ product.generated_code }}
        </p>
      </div>
      <v-tabs
        class="mt-6"
        v-model="tab"
        background-color="deep-purple accent-4"
        center-active
        dark
      >
        <v-tab href="#general-info">{{ $t("products.general_info") }}</v-tab>
        <v-tab href="#pricing">{{ $t("products.pricing") }}</v-tab>
        <v-tab href="#marketing-info">{{
          $t("products.marketing_info")
        }}</v-tab>
      </v-tabs>
      <products-view-loader v-if="!loaded" />
      <v-tabs-items
        v-if="loaded"
        v-model="tab"
      >
        <v-tab-item
          :key="1"
          :value="'general-info'"
        >
          <!-- Genenral Information Section -->
          <div
            v-if="
              !$admin.can('product-update') &&
              $admin.can('product-update-content')
            "
          >
            <general-information
              :product="product"
              :shelf="currentShelf"
              :root="currentRoot"
              :category="currentCategory"
              :mainProvider="
                providers.find(
                  (provider) => product.main_provider.id === provider.id
                )
              "
              :secondaryProviders="
                product.secondary_providers.map((secondary_provider) =>
                  providers.find(
                    (provider) => secondary_provider.id === provider.id
                  )
                )
              "
            />
          </div>
          <div
            v-if="$admin.can('product-update')"
            class="d-flex flex-column flex-md-row"
            style="gap: 50px"
          >
            <!-- General Information - Left Section -->
            <div
              class="flex-grow-1 mt-10"
              style="min-width: 450px"
            >
              <div>
                <h4 class="mb-6">Product Information</h4>
                <v-text-field
                  filled
                  label="Name *"
                  v-model="product.name"
                  required
                  :rules="[
                    rules.field_required('name', product.name),
                    rules.field_length_between('name', 4, 255, product.name),
                  ]"
                ></v-text-field>
                <v-select
                  filled
                  label="Type of product"
                  item-value="value"
                  item-text="name"
                  :items="productTypes"
                  v-model="product.type"
                >
                </v-select>
                <div
                  class="d-flex"
                  v-for="(barcode, index) in product.barcodes"
                  v-bind:key="'barcode' + index"
                  :rules="[rules.required]"
                >
                  <v-text-field
                    filled
                    label="Codebar"
                    v-model="product.barcodes[index].code"
                    @input="debounceCheckBarcode($event, index)"
                    v-bind:loading="checkingBarcode"
                    :rules="[rules.required]"
                    v-on:keypress="NumbersOnly"
                  ></v-text-field>
                  <v-btn
                    text
                    class="mt-2"
                    @click="() => generateBarCode(index)"
                  >
                    <v-icon>mdi-reload</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="index > 0"
                    text
                    class="mt-2"
                    @click="() => removeBarCode(index)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
                <v-btn
                  text
                  @click="addBarCode"
                >
                  <span class="purple--text">+ Add codebar</span>
                </v-btn>
              </div>
              <div class="mt-6">
                <h4 class="mb-6">Categorization</h4>
                <v-autocomplete
                  filled
                  :items="shelves"
                  v-model="currentShelf"
                  @change="shelfChanged($event)"
                  item-value="id"
                  item-text="name"
                  label="Shelf"
                  :rules="[(v) => !!v || 'Shelf is required']"
                >
                </v-autocomplete>
                <v-autocomplete
                  filled
                  :items="currentCategories"
                  v-model="currentRoot"
                  @change="rootChanged($event)"
                  item-value="id"
                  item-text="name"
                  label="Parent Category"
                  :rules="[(v) => !!v || 'Parent category is required']"
                >
                </v-autocomplete>
                <v-autocomplete
                  filled
                  :items="currentSubCategories"
                  v-model="product.category_id"
                  item-value="id"
                  item-text="name"
                  label="Category"
                  :rules="[(v) => !!v || 'Catgory is required']"
                >
                </v-autocomplete>
                <v-combobox
                  filled
                  :label="$t('brand')"
                  v-model="product.brand"
                  :items="brands.map((brand) => brand.name)"
                  :rules="[]"
                ></v-combobox>
              </div>
              <div class="mt-6">
                <h4 class="mb-6">Supplier Information</h4>
                <div class="d-flex">
                  <v-switch
                    class="ml-4 d-inline"
                    v-model="product.main_provider.status"
                  ></v-switch>
                  <v-autocomplete
                    filled
                    :items="providers"
                    item-value="id"
                    v-model="product.main_provider.id"
                    item-text="name"
                    label="Primary Supplier"
                  >
                  </v-autocomplete>
                </div>
                <div
                  class="d-flex"
                  v-for="(provider, index) in product.secondary_providers"
                  v-bind:key="'prodvider' + index"
                >
                  <v-switch
                    v-model="product.secondary_providers[index].status"
                    class="ml-4"
                  ></v-switch>
                  <v-select
                    filled
                    :items="providers"
                    item-value="id"
                    item-text="name"
                    v-model="product.secondary_providers[index].id"
                    label="Secondary Supplier"
                  >
                  </v-select>
                  <v-btn
                    @click="removeProvider(index)"
                    text
                  >
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </div>
                <v-btn
                  text
                  v-on:click="addProvider"
                >
                  <span class="purple--text">+ Add supplier</span>
                </v-btn>
              </div>
            </div>
            <!-- /General Information - Left Section -->
            <!-- General Information - Right Section -->
            <div
              div
              class="flex-grow-1 mt-10"
              style="min-width: 400px"
            >
              <div>
                <h4 class="mb-3">Item Information</h4>
                <v-row class="mt-0">
                  <v-col
                    class="d-flex"
                    style="gap: 10px"
                  >
                    <v-text-field
                      filled
                      label="Item NET weight"
                      v-model="product.product_info.item_net_weight"
                      v-on:keypress="NumbersOnly"
                      :rules="[rules.detailsRule]"
                    >
                    </v-text-field>
                    <v-select
                      filled
                      style="max-width: 100px"
                      label="Unit"
                      :items="weightUnits"
                      v-model="product.product_info.item_net_weight_unit"
                      :rules="[
                        (v) =>
                          !this.product.product_info.item_net_weight ||
                          !!v ||
                          'Unit required',
                      ]"
                    ></v-select>
                  </v-col>
                  <v-col
                    class="d-flex"
                    style="gap: 10px"
                  >
                    <v-text-field
                      filled
                      label="Item GROSS weight"
                      v-model.number="product.product_info.item_gross_weight"
                      v-on:keypress="NumbersOnly"
                      :rules="[rules.detailsRule]"
                    >
                    </v-text-field>
                    <v-select
                      filled
                      style="max-width: 100px"
                      label="Unit"
                      :items="weightUnits"
                      v-model="product.product_info.item_gross_weight_unit"
                      :rules="[
                        (v) =>
                          !this.product.product_info.item_gross_weight ||
                          !!v ||
                          'Unit required',
                      ]"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col
                    class="d-flex"
                    style="gap: 10px"
                  >
                    <v-text-field
                      filled
                      label="Item NET volume"
                      v-model.number="product.product_info.item_net_volume"
                      v-on:keypress="NumbersOnly"
                      :rules="[rules.detailsRule]"
                    >
                    </v-text-field>
                    <v-select
                      filled
                      style="max-width: 100px"
                      label="Unit"
                      :items="volumeUnits"
                      v-model="product.product_info.item_net_volume_unit"
                      :rules="[
                        (v) =>
                          !this.product.product_info.item_net_volume ||
                          !!v ||
                          'Unit required',
                      ]"
                    ></v-select>
                  </v-col>
                  <v-col
                    class="d-flex"
                    style="gap: 10px"
                  >
                    <v-text-field
                      filled
                      label="Item GROSS volume"
                      v-model.number="product.product_info.item_gross_volume"
                      v-on:keypress="NumbersOnly"
                      :rules="[rules.detailsRule]"
                    ></v-text-field>
                    <v-select
                      filled
                      style="max-width: 100px"
                      label="Unit"
                      v-model="product.product_info.item_gross_volume_unit"
                      :rules="[
                        (v) =>
                          !this.product.product_info.item_gross_volume ||
                          !!v ||
                          'Unit required',
                      ]"
                      :items="volumeUnits"
                    ></v-select>
                  </v-col>
                </v-row>
              </div>
              <div
                class="d-flex"
                style="gap: 30px"
              >
                <div class="flex-grow-1">
                  <h4 class="mb-6">Package Information</h4>
                  <v-text-field
                    filled
                    label="Units Per Package"
                    v-model.number="product.product_info.package_units"
                    v-on:keypress="NumbersOnly"
                    :rules="[rules.detailsRule]"
                  ></v-text-field>
                  <v-text-field
                    filled
                    label="Packcage Width (cm)"
                    v-model.number="product.product_info.package_width"
                    v-on:keypress="NumbersOnly"
                  ></v-text-field>
                  <v-text-field
                    filled
                    label="Package Depth (cm)"
                    v-model.number="product.product_info.package_depth"
                    v-on:keypress="NumbersOnly"
                  ></v-text-field>
                  <v-text-field
                    filled
                    label="Package Height (cm)"
                    v-model.number="product.product_info.package_height"
                    v-on:keypress="NumbersOnly"
                  ></v-text-field>
                  <v-row class="mt-0">
                    <v-col
                      class="d-flex"
                      style="gap: 10px"
                    >
                      <v-text-field
                        filled
                        label="Package NET weight"
                        v-model.number="product.product_info.package_net_weight"
                        v-on:keypress="NumbersOnly"
                        :rules="[rules.detailsRule]"
                      ></v-text-field>
                      <v-select
                        filled
                        style="max-width: 100px"
                        label="Unit"
                        v-model="product.product_info.package_net_weight_unit"
                        :rules="[
                          (v) =>
                            !this.product.product_info.package_net_weight ||
                            !!v ||
                            'Unit required',
                        ]"
                        :items="weightUnits"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col
                      class="d-flex"
                      style="gap: 10px"
                    >
                      <v-text-field
                        filled
                        label="Package GROSS weight"
                        v-model="product.product_info.package_gross_weight"
                        v-on:keypress="NumbersOnly"
                        :rules="[rules.detailsRule]"
                      ></v-text-field>
                      <v-select
                        filled
                        style="max-width: 100px"
                        label="Unit"
                        v-model="product.product_info.package_gross_weight_unit"
                        :rules="[
                          (v) =>
                            !this.product.product_info.package_gross_weight ||
                            !!v ||
                            'Unit required',
                        ]"
                        :items="weightUnits"
                      ></v-select>
                    </v-col>
                  </v-row>
                </div>
                <div class="flex-grow-1">
                  <h4 class="mb-6">Palette Information</h4>
                  <v-text-field
                    filled
                    label="Packages Per Palette"
                    v-model.number="product.product_info.palette_units"
                    v-on:keypress="NumbersOnly"
                    :rules="[rules.detailsRule]"
                  ></v-text-field>
                  <v-text-field
                    filled
                    label="Palette Width (cm)"
                    v-model.number="product.product_info.palette_width"
                    v-on:keypress="NumbersOnly"
                    :rules="[rules.detailsRule]"
                  ></v-text-field>
                  <v-text-field
                    filled
                    label="Palette Depth (cm)"
                    v-model.number="product.product_info.palette_depth"
                    v-on:keypress="NumbersOnly"
                    :rules="[rules.detailsRule]"
                  ></v-text-field>
                  <v-text-field
                    filled
                    label="Palette Height (cm)"
                    v-model.number="product.product_info.palette_height"
                    v-on:keypress="NumbersOnly"
                    :rules="[rules.detailsRule]"
                  ></v-text-field>
                  <v-row class="mt-0">
                    <v-col
                      class="d-flex"
                      style="gap: 10px"
                    >
                      <v-text-field
                        filled
                        label="Palette NET weight"
                        v-model.number="product.product_info.palette_net_weight"
                        v-on:keypress="NumbersOnly"
                        :rules="[rules.detailsRule]"
                      >
                      </v-text-field>
                      <v-select
                        filled
                        style="max-width: 100px"
                        label="Unit"
                        v-model="product.product_info.palette_net_weight_unit"
                        :rules="[
                          (v) =>
                            !this.product.product_info.palette_net_weight ||
                            !!v ||
                            'Unit required',
                        ]"
                        :items="weightUnits"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col
                      class="d-flex"
                      style="gap: 10px"
                    >
                      <v-text-field
                        filled
                        label="Palette GROSS weight"
                        v-model.number="
                          product.product_info.palette_gross_weight
                        "
                        v-on:keypress="NumbersOnly"
                        :rules="[rules.detailsRule]"
                      ></v-text-field>
                      <v-select
                        filled
                        style="max-width: 100px"
                        label="Unit"
                        v-model="product.product_info.palette_gross_weight_unit"
                        :rules="[
                          (v) =>
                            !this.product.product_info.palette_gross_weight ||
                            !!v ||
                            'Unit required',
                        ]"
                        :items="weightUnits"
                      ></v-select>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div>
                <h4 class="mb-6">Supply Information</h4>
                <div
                  class="d-flex"
                  style="gap: 10px"
                >
                  <v-select
                    :loading="isDeliveryTypesLoading"
                    filled
                    label="Point of supply"
                    v-model="product.point_of_supply"
                    item-text="name"
                    item-value="value"
                    :items="[
                      {
                        name: '',
                        code: '',
                        value: '',
                      },
                      ...deliveryTypes.map((deliveryType) => {
                        const value = deliveryType.name;
                        const name = deliveryType.name.toUpperCase();
                        const code = deliveryType.code;
                        return {
                          name,
                          code,
                          value,
                        };
                      }),
                    ]"
                  ></v-select>
                  <v-select
                    filled
                    label="Imported product"
                    v-model="product.imported"
                    :items="[
                      {
                        text: '',
                        value: '',
                      },
                      {
                        text: 'Yes',
                        value: true,
                      },
                      {
                        text: 'No',
                        value: false,
                      },
                    ]"
                  ></v-select>
                </div>
                <v-text-field
                  filled
                  disabled
                  label="Old Code"
                  v-model="product.code"
                  style="max-width: 320px"
                ></v-text-field>
              </div>
            </div>
            <!-- General Information - Right Section -->
          </div>
          <!-- /General Information Section -->
        </v-tab-item>
        <v-tab-item
          :key="2"
          :value="'pricing'"
        >
          <div
            class="mt-10 d-flex"
            style="gap: 50px"
          >
            <div
              v-if="$admin.can('product-update')"
              class="flex-grow-1"
            >
              <h4 class="mb-6">Product Information</h4>
              <v-text-field
                filled
                label="Puchase price (DZD)"
                v-model.number="product.purchase_price_ht"
                v-on:keypress="NumbersOnly"
              ></v-text-field>
              <v-select
                filled
                label="Purchase TVA (%)"
                v-model="product.purchase_tva"
                :items="VATs"
              ></v-select>

              <v-text-field
                filled
                v-model="product.price"
                label="Selling price (incl. TVA in DZD)"
                v-on:keypress="NumbersOnly"
                :rules="[
                  (v) =>
                    this.calculateMarge() > 0 ||
                    !v ||
                    $t('selling_price_condition'),
                ]"
              ></v-text-field>
              <v-select
                filled
                label="Selling TVA (%)"
                v-model="product.tva"
                :items="VATs"
              ></v-select>
            </div>
            <div class="flex-grow-1">
              <h4 class="mt-2">Pricing details</h4>
              <div class="mt-8 font-weight-medium">
                <p class="d-flex justify-space-between my-2">
                  <span>Prix d'achat unitaire TTC</span>
                  <span>
                    {{
                      product.purchase_price_ht &&
                      product.purchase_tva !== undefined &&
                      new Intl.NumberFormat("fr-FR", {
                        style: "currency",
                        currency: "DZD",
                      }).format(
                        product.purchase_price_ht *
                          (1 + product.purchase_tva / 100)
                      )
                    }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-2">
                  <span>Purchse TVA</span>
                  <span>{{
                    product.purchase_tva && product.purchase_tva + "%"
                  }}</span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-2">
                  <span>Prix de vente HT</span>
                  <span>
                    {{
                      product.price &&
                      product.tva != undefined &&
                      new Intl.NumberFormat("fr-FR", {
                        style: "currency",
                        currency: "DZD",
                      }).format(product.price / (1 + product.tva / 100))
                    }}
                  </span>
                </p>

                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-2">
                  <span>Selling TVA</span>
                  <span>{{ product.tva && product.tva + "%" }}</span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-2">
                  <span>Marge</span>
                  <span>
                    {{
                      product.purchase_price_ht &&
                      product.purchase_tva != undefined &&
                      product.price &&
                      calculateMarge() + "%"
                    }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-2">
                  <span>Margin Objective</span>
                  <span
                    v-bind:class="{
                      'light-green--text':
                        calculateMarge() >= this.currentShelf?.margin,
                      'red--text': calculateMarge() < this.currentShelf?.margin,
                    }"
                    >{{ this.currentShelf?.margin }}%</span
                  >
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
              </div>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item
          disabled
          :key="3"
          :value="'marketing-info'"
        >
          <div
            class="mt-10"
            style="max-width: 700px"
          >
            <h4>Marketing détails</h4>
            <div class="mt-8 font-weight-medium">
              <v-text-field
                filled
                :label="$t('marketing_designation_*')"
                v-model="product.name_marketing"
                required
                :rules="[
                  rules.field_required(
                    'marketing_designation',
                    product.name_marketing
                  ),
                  rules.field_length_between(
                    'marketing_designation',
                    4,
                    255,
                    product.name_marketing
                  ),
                ]"
              ></v-text-field>
              <p class="d-flex justify-space-between align-center my-2">
                <span>Express Status</span>
                <v-switch v-model="product.express_status"></v-switch>
              </p>
              <hr style="border-top: 1px solid #d3d3d3" />
              <div class="mt-2">
                <span>Short Description</span>
                <v-textarea
                  outlined
                  class="my-4"
                  v-model="product.short_description"
                />
              </div>
              <hr style="border-top: 1px solid #d3d3d3" />
              <div class="my-2">
                <span>Description</span>
                <vue-editor
                  class="my-4"
                  v-model="product.description"
                />
              </div>
              <hr style="border-top: 1px solid #d3d3d3" />
              <p class="my-2">
                <span>Special Categories</span>
                <v-autocomplete
                  class="mt-4"
                  filled
                  v-model="product.special_category_ids"
                  :items="leafSpecialCategories"
                  item-value="id"
                  item-text="name"
                  :label="$t('special_sub_category_*')"
                  multiple
                  chips
                >
                </v-autocomplete>
              </p>
              <hr style="border-top: 1px solid #d3d3d3" />
            </div>
            <div class="d-flex justify-space-between align-center my-2">
              <span>Product Image</span>
              <file-preview
                ref="filePreview"
                :image="product.image"
              ></file-preview>
            </div>
            <hr style="border-top: 1px solid #d3d3d3" />
          </div>
        </v-tab-item>
      </v-tabs-items>
      <div class="mt-10">
        <v-btn
          depressed
          color="primary"
          class="me-2"
          v-on:click="submit"
          :loading="isLoading"
          :disabled="isLoading || this.calculateMarge() <= 0"
        >
          Save Changes
        </v-btn>
        <v-btn
          text
          @click="returnToList()"
        >
          Cancel
        </v-btn>
      </div>
    </v-container>
  </v-form>
</template>
<script>
import { mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";
import "./form-style.css";
import { Barcode, Product, ProductInfo, Provider } from "./product.class";
import { validationRules } from "@/mixins/validationRules";
import _ from "lodash";
import productService from "@/store/services/product-service";
import FilePreview from "./FilePreview";
import ProductsViewLoader from "./ProductsViewLoader";
import GeneralInformation from "./GeneralInformation.vue";

export default {
  mixins: [validationRules],
  components: {
    VueEditor,
    FilePreview,
    ProductsViewLoader,
    GeneralInformation,
  },
  data() {
    return {
      tab: null,
      valid: false,
      loaded: false,
      product: new Product(),
      productEntity: null,
      currentRoot: null,
      currentShelf: null,
      currentCategory: null,
      currentCategories: [],
      currentSubCategories: [],
      content: "<p>Product Description</p>",
      volumeUnits: ["ml", "cl", "L", "mm3", "cm3", "m3"],
      weightUnits: ["g", "kg"],
      productTypes: [
        {
          name: "Simple",
          value: "simple",
        },
        // {
        //   name: "Package",
        //   value: "package",
        // },
        // {
        //   name: "Bundle",
        //   value: "bundle",
        // },
      ],
      checkingBarcode: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoadingShelves: "categories/isLoadingCategories",
      shelves: "categories/listShelves",
      categories: "categories/listRoots",
      leafs: "categories/listLeafs",
      brands: "brands/activeBrands",
      providers: "providers/listProviders",
      leafSpecialCategories: "specialCategories/listLeafs",
      priceSettings: "settings/priceSettings",
      deliveryTypes: "deliveryTypes/list",
      isDeliveryTypesLoading: "deliveryTypes/isLoading",
    }),

    VATs() {
      return this.priceSettings.vat_array.map((vat) => ({
        text: vat + "%",
        value: vat,
      }));
    },
  },

  methods: {
    loadProductTypes() {},
    loadShelves() {
      return this.$store.dispatch("categories/listShelves");
    },
    loadCategories() {
      return this.$store.dispatch("categories/listRoots");
    },
    loadSubCategories() {
      return this.$store.dispatch("categories/listLeafs");
    },
    loadBrands() {
      return this.$store.dispatch("brands/list");
    },
    loaderProviders() {
      return this.$store.dispatch("providers/list");
    },
    loaderPriceSettings() {
      return this.$store.dispatch("settings/getPriceSettings");
    },

    loadDeliveryTypes() {
      return this.$store.dispatch("deliveryTypes/list");
    },

    init() {
      this.loadProductTypes();
      this.loadShelves();
      this.loadCategories();
      this.loadSubCategories();
      this.loadBrands();
      this.loaderProviders();
      this.loaderPriceSettings();
      this.loadDeliveryTypes();
      this.$store.dispatch("specialCategories/listLeafs");
    },
    returnToList() {
      this.$swal({
        icon: "question",
        text: "Product won't be saved? Are you sure?",
        showConfirmButton: false,
        showDenyButton: true,
        showCancelButton: true,
        denyButtonText: `Don't save`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isDenied) {
          this.$router.push("/setup/products");
        }
      });
    },
    async validate() {
      await this.$refs.form?.validate();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async saveImage() {
      const image = this.$refs.filePreview?.$data.image;
      if (image) {
        const formData = new FormData();
        formData.append("image_file", image);
        await productService.saveImage(this.product, formData);
      }
    },
    async submit() {
      this.isLoading = true;
      // this.product.tva = this.product.purchase_tva; we added tva field
      const image = this.$refs.filePreview?.$data.image;
      const productUpdateDto = new Product();
      Object.keys(productUpdateDto).forEach((key) => {
        productUpdateDto[key] = this.product[key];
      });
      if (image) {
        productUpdateDto["image_file"] = image;
      }
      await this.validate();
      if (!this.valid) {
        this.isLoading = false;
        return;
      }
      try {
        const savedProduct = await productService.update(productUpdateDto);
        if (savedProduct) {
          // this.saveImage();
          this.$swal("", "Product created successfully", "success");
          this.$router.push("/setup/products");
        }
      } catch (error) {
        this.isLoading = false;
        this.$store.dispatch("alerts/error", error.response?.data?.message);
        // this.formErrors = error.response.data.errors;
        this.is_loading_save = false;
      }
    },
    addProvider() {
      if (this.product.secondary_providers.length >= 3) {
        this.$swal("", "Provider limit reached", "info");
      } else {
        this.product.secondary_providers.push({
          id: null,
          status: false,
        });
      }
    },
    calculateMarge() {
      return Number(
        (100 *
          (this.product.price / (1 + this.product.tva / 100) -
            this.product.purchase_price_ht)) /
          this.product.purchase_price_ht
      ).toFixed(2);
    },

    removeProvider(providerIndex) {
      this.product.secondary_providers =
        this.product.secondary_providers.filter(
          (value, index) => index !== providerIndex
        );
    },
    async generateBarCode(index) {
      this.checkingBarcode = true;
      if (!this.product.barcodes[index].code) {
        const generatedBarcode = await productService.generateBarcode();
        this.product.barcodes[index].code = parseInt(generatedBarcode);
        if (index === 0) this.product.barcodes[index].is_main = true;
      }
      this.checkingBarcode = false;
      // this.product.barcodes[index].code = parseInt(
      //   "200" + Math.random().toString().substr(2, 10)
      // );
    },
    addBarCode() {
      if (this.product.barcodes.length >= 3) {
        this.$swal("", "Barcode limit reached", "info");
      } else {
        this.product.barcodes.push({
          is_main: false,
          code: null,
        });
      }
    },
    removeBarCode(barcodeIndex) {
      this.product.barcodes = this.product.barcodes.filter(
        (value, index) => index !== barcodeIndex
      );
    },
    shelfChanged(value) {
      this.currentRoot = null;
      this.currentCategories = this.categories.filter(
        (category) => category.parent_id === value
      );
    },
    rootChanged(value) {
      this.product.category_id = null;
      this.currentSubCategories = this.leafs.filter(
        (leaf) => leaf.parent_id === value
      );
    },
    NumbersOnly(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    debounceCheckBarcode: _.debounce(function (e, index) {
      this.checkBarCode(e, index);
    }, 1000),
    checkBarCode(event, index) {
      this.checkingBarcode = true;
      setTimeout(() => {
        console.log("TODO: Check the barcode", event, index);
        this.checkingBarcode = false;
      }, 2000);
    },
  },
  watch: {
    product: function (newProduct) {
      const currentCategoryObject = this.leafs.find(
        (category) => category.id == newProduct?.category_id
      );
      this.currentCategory = currentCategoryObject;
      const root = this.categories.find(
        (category) => category.id == currentCategoryObject?.parent_id
      );
      this.currentRoot = root;
      const shelf = this.shelves.find((shelf) => shelf.id == root?.parent_id);
      this.currentShelf = shelf;
      this.shelfChanged(shelf?.id);
      this.currentRoot = root;
      this.rootChanged(root?.id);
      this.product.category_id = currentCategoryObject?.id;
    },
  },
  async created() {
    this.init();
    this.productEntity = await productService.show({
      id: this.$route.params.id,
    });
    this.product = {
      ...this.productEntity,
      brand: this.productEntity.brand?.name,
      imported: this.productEntity.imported === 1,
      purchase_tva: parseInt(this.productEntity.purchase_tva),
      tva: parseInt(this.productEntity.tva),
      product_info: this.productEntity.product_info ?? new ProductInfo(),
      barcodes:
        this.productEntity?.barcodes?.length > 0
          ? this.productEntity.barcodes
          : [new Barcode(null, true)],
      main_provider: this.productEntity.main_provider ?? [new Provider()],
    };
    this.loaded = true;
  },
};
</script>
